<template>
  <v-card>
    <v-card-title>Create Vendor</v-card-title>
    <v-container>
      <UserForm :dbUser="dbUser" :umUser="umUser" type="CREATE" />
    </v-container>
  </v-card>
</template>

<script>
import UserForm from "./form";
export default {
  components: {
    UserForm
  },
  data() {
    return {
      dbUser: {},
      umUser: {type: "vendor"}
    };
  }
};
</script>